<template>
  <div class="container">
    <div class="main-content">
      <!-- 表格输入区域 -->
      <table class="input-table">
        <thead>
          <tr>
            <th>单价</th>
            <th>数量</th>
            <th>小计</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(row, index) in rows"
            :key="index"
          >
            <td>
              <input
                type="number"
                step="0.1"
                v-model.number="row.price"
                placeholder="0.0"
                class="input-cell"
                :ref="'priceRef' + index"
                @keydown.enter="handlePriceEnter(index)"
              >
            </td>
            <td>
              <input
                type="number"
                step="1"
                v-model.number="row.quantity"
                placeholder="0"
                class="input-cell"
                :ref="'quantityRef' + index"
                @keydown.enter="handleQuantityEnter(index)"
              >
            </td>
            <td class="result-cell">
              {{ formatNumber(calculateRowTotal(row)) }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 右侧控制区域 -->
      <div class="control-section">
        <div class="control-group">
          <button @click="calculateTotal">计算总和</button>
          <button @click="clearAll" class="clear-btn">清空所有</button>
        </div>
        <div class="total-result">
          总金额：{{ formatNumber(totalSum) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: Array.from({ length: 16 }, () => ({
        price: null,
        quantity: null
      })),
      totalSum: 0
    }
  },
  methods: {
    calculateRowTotal(row) {
      const price = Number(row.price) || 0
      const quantity = Number(row.quantity) || 0
      return Math.round(price * quantity * 10) / 10
    },
    calculateTotal() {
      this.totalSum = this.rows.reduce((acc, row) => {
        return acc + this.calculateRowTotal(row)
      }, 0)
    },
    handlePriceEnter(index) {
      if (index < 15) {
        this.$nextTick(() => {
          this.$refs[`priceRef${index + 1}`][0].focus()
        })
      } else {
        this.$nextTick(() => {
          this.$refs.quantityRef0[0].focus()
        })
      }
    },
    handleQuantityEnter(index) {
      if (index < 15) {
        this.$nextTick(() => {
          this.$refs[`quantityRef${index + 1}`][0].focus()
        })
      }
    },
    clearAll() {
      this.rows = this.rows.map(() => ({
        price: null,
        quantity: null
      }))
      this.totalSum = 0
      this.$nextTick(() => {
        this.$refs.priceRef0[0].focus()
      })
    },
    formatNumber(value) {
      return Number(value).toFixed(1)
    }
  }
}
</script>

<style scoped>
/* 保持原有样式不变 */
.container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
}

.main-content {
  display: flex;
  gap: 40px;
}

.input-table {
  flex: 3;
  border-collapse: collapse;
  width: 100%;
}

.input-table th,
.input-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #e0e0e0;
}

.input-table th {
  background-color: #f5f7fa;
  font-weight: 600;
  color: #2c3e50;
}

.input-cell {
  width: 100%;
  padding: 8px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-cell:focus {
  border-color: #409eff;
  outline: none;
}

.result-cell {
  font-weight: 500;
  color: #67c23a;
  min-width: 100px;
}

.control-section {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 40px;
}

.control-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

button {
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

button:first-child {
  background-color: #409eff;
  color: white;
  border: none;
}

button:first-child:hover {
  background-color: #66b1ff;
}

.clear-btn {
  background-color: #f56c6c;
  color: white;
  border: none;
}

.clear-btn:hover {
  background-color: #f78989;
}

.total-result {
  padding: 15px;
  background-color: #f5f7fa;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #303133;
}
</style>